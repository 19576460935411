import useColorSetStore from 'stores/colorSet';
import useMachineStore from 'stores/machine';
import Clock from '../clock/Clock';
import BusinessHour from '../info/business-hour/BusinessHour';
import ClosedDays from '../info/closed-days/ClosedDays';
import styles from './HoriMallInfo.module.scss';

const HoriMallInfo = () => {
  // store
  const machineStore = useMachineStore();
  const colorSetStore = useColorSetStore();

  const isShowClosedDays = machineStore.machine?.point.closedDaysUsed;

  // 운영시간과 휴무일 사용하지 않으면 시계 노출
  const isShowClock = !machineStore.machine?.point.hoursUsed && !machineStore.machine?.point.closedDaysUsed;

  return (
    <div
      className={styles.mall_info_wrapper}
      style={{
        background: colorSetStore.footer.bg,
      }}
    >
      {isShowClock ? (
        // 시계
        <Clock />
      ) : (
        // 운영시간, 휴무일
        <div className={styles.mall_info}>
          {/* 운영시간 */}
          <BusinessHour />

          {/* 휴무일 */}
          {isShowClosedDays && <ClosedDays />}
        </div>
      )}
    </div>
  );
};
export default HoriMallInfo;
