import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './Layout.module.scss';
import Footer from './footer/Footer';

const Layout = () => (
  <div className={styles.layout}>
    <Outlet /> {/* 중첩된 Route가 여기에 렌더링됨 */}
    <Footer />
  </div>
);

export default Layout;
