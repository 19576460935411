import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useColorSetStore from 'stores/colorSet';
import useMachineStore from 'stores/machine';
import { PathType } from 'types/common/common.type';
import { MainMenu } from 'types/feature/menu.type';
import useDomainStore from 'stores/domain';
import styles from './MainButton.module.scss';
import Home from './home/Home';

type Props = {
  menu: MainMenu;
};

const MainButton = ({ menu }: Props) => {
  // store
  const colorSetStore = useColorSetStore();
  const machineStore = useMachineStore();
  const domainStore = useDomainStore();

  // hook
  const location = useLocation();
  const navigate = useNavigate();
  const { clientPath } = useParams();

  // WEB 접속시 Active check
  const webIsActiveCheck = () => {
    if (domainStore.domainType === 'WEB') {
      const parts = location.pathname.split('/').filter(Boolean);
      const clientPathId = parts[parts.length - 1];
      if (clientPathId === clientPath) {
        return true;
      }
      return false;
    }
    return false;
  };

  const isActiveMain = location.pathname === `/${PathType.home}` || location.pathname === '/' || webIsActiveCheck();

  // domain type 에 따른 분기
  const onClick = () => {
    if (domainStore.domainType === 'WEB') {
      navigate(`/web/${clientPath}`);
    } else {
      navigate(`/${PathType.home}`, { replace: true });
    }
  };

  return (
    <div
      style={{ background: `${isActiveMain ? colorSetStore.gnb.bg.active : colorSetStore.gnb.bg.inactive}` }}
      className={`${styles.main}`}
      onClick={onClick}
    >
      {
        // active 이고 로고 있을 경우
        isActiveMain && machineStore.machine?.point.logoUri ? (
          <div className={styles.logo}>
            <img src={machineStore.machine.point.logoUri} alt='logo' />
          </div>
        ) : (
          // 나머지 경우
          <Home isActive={isActiveMain} menu={menu} />
        )
      }
    </div>
  );
};
export default MainButton;
