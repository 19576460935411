/* eslint-disable no-console */
import { useEffect } from 'react';
import AuthRoute from 'component/common/auth/AuthRoute';
import { logConsole } from 'component/common/console/console';
import Layout from 'component/common/layout/Layout';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import useDomainTypeAndWebInitInfo from 'hooks/fetch/useDomainTypeAndWebInitInfo';
import useFetchServerApis from 'hooks/fetch/useFetchServerApis';
import useResetSection from 'hooks/reset/useResetSection';
import useScript from 'hooks/script/useScript';
import MapDraw from 'map/MapDraw';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES, ROUTES_WEB } from 'routes.config';
import useMenuStore from 'stores/menu';
import useMultiLangStore from 'stores/multiLang';
import useScreenTypeStore from 'stores/screenType';
import { PathType } from 'types/common/common.type';
import { validResolution } from 'utils/resolution/validResolution';
import { returnScreenType } from 'utils/screen-type/returnScreenType';
import NoAuthRoute from 'component/common/auth/NoAuthRoute';
import MainPage from 'pages/MainPage';
import NotFound from 'pages/NotFound';
import InitialLoadingPage from 'pages/InitialLoadingPage';

function App() {
  // 지도 script 불러오기
  useScript();

  // 일정시간 후 홈으로 돌아오도록 처리
  useResetSection();

  // hook
  const location = useLocation();
  const navigate = useNavigate();
  useDomainTypeAndWebInitInfo(); // domain typ
  const { fetchApiLoaded, machineAccessToken } = useFetchServerApis();

  // store
  const screenTypeStore = useScreenTypeStore();
  const multiLangStore = useMultiLangStore();
  const menuStore = useMenuStore();

  // 초기 로딩 완료 여부
  const isInitialLoading = !fetchApiLoaded || !MapDraw.mapData;

  // 사용할 수 없는 해상도에서 앱에 접근 시 에러페이지로 이동
  const moveToResolutionErrorPage = () => {
    if (!validResolution()) {
      navigate(`/${PathType.resolutionError}`, { replace: true });
    }
  };

  // 현재 앱의 screenType 반환 (가로형/세로형/정방형)
  const handleScreenType = () => {
    screenTypeStore.setScreenType(returnScreenType());

    moveToResolutionErrorPage();
  };

  useEffect(() => {
    logConsole('📐 Screen Type :', screenTypeStore.screenType);
  }, [screenTypeStore.screenType]);

  useEffect(() => {
    logConsole('📱 User Agent :', (window.navigator as any).userAgentData);
    logConsole('current app mode :', process.env.REACT_APP_ENVIRONMENT);

    handleScreenType();
    dayjs.locale('ko');

    window.addEventListener('resize', handleScreenType);

    return () => {
      window.removeEventListener('resize', handleScreenType);
    };
  }, []);

  /**
   * @desc menuStore.mainMenus, location 때 마다 현재메뉴를 저장한다.
   */
  useEffect(() => {
    if (menuStore.mainMenus.length > 0) {
      menuStore.setCurMenu(location, menuStore.mainMenus);
    }
  }, [menuStore.mainMenus, location]);

  return (
    <div onClick={multiLangStore.closeMultiLang} className={`App ${screenTypeStore.screenType}`}>
      {isInitialLoading ? (
        <InitialLoadingPage />
      ) : (
        <Routes>
          <Route element={<Layout />}>
            {/* 기본 경로 */}
            <Route path='*' element={<NotFound />} />
            <Route path='/' element={<MainPage />} />
            {ROUTES.map(({ path, isProtected, isError, screen }) => (
              <Route
                key={path}
                path={path}
                element={
                  <AuthRoute
                    screen={screen}
                    isProtected={isProtected}
                    isError={isError}
                    isLoading={isInitialLoading}
                    token={machineAccessToken}
                  />
                }
              />
            ))}
            {/* 개별 페이지 /web/:id */}
            <Route path='/web/:clientPath'>
              <Route
                index
                element={
                  <NoAuthRoute
                    screen={<MainPage />}
                    isProtected={false}
                    isError={false}
                    isLoading={isInitialLoading}
                    token={machineAccessToken}
                  />
                }
              />
              {ROUTES_WEB.map(({ path, isProtected, isError, screen }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <NoAuthRoute
                      screen={screen}
                      isProtected={isProtected}
                      isError={isError}
                      isLoading={isInitialLoading}
                      token={machineAccessToken}
                    />
                  }
                />
              ))}
            </Route>
          </Route>
        </Routes>
      )}
    </div>
  );
}

export default App;
